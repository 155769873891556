import React, { useEffect } from "react"

import { Link } from "gatsby"

import Sections from "../components/adoption/sections"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ApplySection from "../components/shared/apply-section"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import InnerIntro from "../components/shared/inner-intro"
import banner from "../images/adoption-banner-bg.jpg"

const AdoptionPage = () => {
  useEffect(() => {
    var m = true;
    if (m) {
      if (window.location.hash) {
        window.scroll({ top: (parseFloat(document.getElementById(window.location.hash.replace("#", "")).offsetTop) - 160), left: 0, behavior: 'smooth' });
      }
    } return () => {
      m = false;
    }
  }, []);
  return <Layout colored_header={true}>
    <Seo title="Adoption" />
    <InnerBanner theme="white" image={banner} title="Adoption" subtitle="Get Involved" />
     <Sections.Section3 />
    <Sections.Section2 />
     <Sections.Section4 />
  
   <InnerIntro theme="white" title="Overview of Our Adoption Process" />
     <Sections.Section1 />
    <ApplySection theme="adoption-apply">
      <Link className="btn-accent btn-volunteer" to="/adoption-process/">Apply for Adoption</Link>
    </ApplySection>
    <HowToHelp />
  </Layout>
}

export default AdoptionPage