import "./sections.css"

import React from "react"

import { StaticImage } from "gatsby-plugin-image"

const Section1 = () => {
    const data = [
        { icon: <StaticImage quality={100} src="../../images/resume-icn.svg" layout="constrained" />, desc: `<p>A potential adopter completes the adoption application.  DFWLRRC has the right to deny or refuse any application.</p>` },
        { icon: <StaticImage src="../../images/home-icn.svg" layout="constrained" />, desc: `<p>The Applications Team reviews the application and if approved, the applicant is scheduled for a home visit by one of our volunteers.</p>` },
        { icon: <StaticImage src="../../images/check-circle--icn.svg" layout="constrained" />, desc: `<p>Once the home visit is complete, the Applications Team forwards application to the Director of Adoptions who will determine if the application is approved.</p>` },
        { icon: <StaticImage src="../../images/phone-call-icn.svg" layout="constrained" />, desc: `<p>The approved adopter is then contacted by the Director of Adoptions and informed about the matching process.  They are given the contact information for the Adoptions Team and encouraged to provide a list of Labs they are interested in meeting. </p>` },
        { icon: <StaticImage src="../../images/jack-russell-terrier-icn.svg" layout="constrained" />, desc: `<p>If no Labs are requested, every effort will be made to provide matches with available Labs.</p>` },
        { icon: <StaticImage src="../../images/veterinarian-icn.svg" layout="fixed" />, desc: `<p>Following a match, the approved adopter contacts the foster family to learn more about the matched Lab and can arrange a time to meet the Lab, and if necessary, meet existing family animals.</p>` },
        { icon: <StaticImage src="../../images/complete-icn.svg" layout="constrained" />, desc: `<p>Once a dog is agreed upon, the adoption process is completed when the new family signs the adoption contract, pays the adoption donation, and has secured the necessary items for the new family pet (collar, leash, ID tag, crate, etc.)</p>` },
        { icon: <StaticImage src="../../images/dog-potential-icn.svg" layout="constrained" />, desc: `<p>Potential adopters are welcome to attend any Meet and Greets.</p>` },
    ]
    return <section className="adoption-sec1">
        <div className="content">
          
            <div className="items">
                {data && data.map((item, key) => {
                    return <div key={key} className="item">
                        <div className="icon">{item.icon}</div>
                        <div className="desc" dangerouslySetInnerHTML={{ __html: item.desc }} />
                    </div>
                })}
            </div>
            <p>On average, it can take between one and five months to complete the process of applying, having a home visit done, meeting potential Labs and adopting a Rescue Lab, depending upon your personal circumstances and flexibility, the number of families waiting to adopt, and the number of dogs available for adoption.</p>
        </div>
    </section>
}

const Section2 = () => {

    return <section className="adoption-sec2">
        <div className="content">
            <h3>Adoption Fees</h3>
            <div className="fees"><span className="price">$400</span> <span className="text1">FOR PUPPIES</span> <span className="text2">(age under 6 months old)</span></div>
            <div className="fees"><span className="price">$325</span> <span className="text1">FOR ADULTS</span> <span className="text2">(age 6 months and older)</span></div>

            <div className="text">
                <p>The adoption fee helps us meet the boarding and veterinary expenses incurred by our non-profit organization, although our average expense per Lab is still far greater than the adoption fee.</p>

                <p>Remember, DFWLRRC has the right to deny or refuse any application.</p>
            </div>
        </div>
    </section>
}

const Section3 = () => {
    const data = [
        { icon: <StaticImage src="../../images/veterinarian-1-icn.svg" layout="constrained" />, desc: `<p>Once accepted into our program all Labs get a trip to our veterinarian for complete checkups, required immunizations, heartworm tests/treatments, spaying or neutering (if necessary), and any other immediate medical treatments needed.</p>` },
        { icon: <StaticImage src="../../images/animal-shelter-icn.svg" layout="constrained" />, desc: `<p>Once in a foster home, the rescue Lab is introduced to a warm, loving, safe environment. Depending on the dog and the foster, he/she may be crate trained, receive some basic obedience training, and complete any necessary follow-up care prescribed by our vets. Our rescue Labs are treated like members of the family and receive all the spoils a Lab deserves.</p>` },
        { icon: <StaticImage src="../../images/feather-calendar-icn.svg" layout="constrained" />, desc: `<p>The minimum stay in a foster home is 12 days. This time helps the foster home to get to know the Lab and to make a good decision when making an adoption.</p>` },
    ]
    return <section className="adoption-sec3">
        <div className="content">
            <h3>Overview Of Our Rescue Process</h3>
            <div className="items">
                {data && data.map((item, key) => {
                    return <div key={key} className="item">
                        <div className="icon">{item.icon}</div>
                        <div className="desc" dangerouslySetInnerHTML={{ __html: item.desc }} />
                    </div>;
                })}
            </div>
        </div>
    </section>
}
const Section4 = () => {
    const data = [
        { icon: <StaticImage src="../../images/plans-icn.svg" layout="constrained" />, desc: `<p>The dog will not be kept outdoors during the adopter’s working hours, or at any other time left alone outdoors while the adopter is not at home. The dog may be confined to a room such as a bedroom or basement, or any other room of adequate size, and protected from the elements of weather</p>` },
        { icon: <StaticImage src="../../images/fence-icn.svg" layout="constrained" />, desc: `<p>Yard is securely fenced (Adoptions to apartment dwellers depend on the dog requested).</p>` },
        { icon: <StaticImage src="../../images/sustainable-home-icn.svg" layout="constrained" />, desc: `
        <p class='mb-4'>Forever home is in one of the following DFW and nearby counties. Our Core Area of coverage is Dallas, Denton, Collin and Tarrant Counties.</p>
        <p class='mb-4'>In addition to our Core Area: We are currently accepting Adoption Applications from an Extended Area that includes: Ellis, Grayson, Hood, Hunt, Johnson, Parker, Rockwall, Somervell and Wise Counties.</p>
        <p class='mb-4'><strong>Please Note:</strong> In order to expand to these new counties, applicants from Extended Area counties will be responsible for driving to the Foster homes in our Core Area counties to meet dogs they have been matched to.</p>
        ` },
        { icon: <StaticImage src="../../images/children-icn.svg" layout="constrained" />, desc: `<p>No children under the age of 6 unless they have been raised with large dogs.</p>` },
        { icon: <StaticImage src="../../images/dog-trainer-icn.svg" layout="constrained" />, desc: `<p>Has an adult who wants to train and care for the dog</p>` },
        { icon: <StaticImage src="../../images/veterinarian-2-icn.svg" layout="constrained" />, desc: `<p>Existing and recent pets must be spayed/neutered, on heartworm preventative, and current on all recommended vaccinations. (Please specify if there is a medical reason any of these conditions are not met.)</p>` },
        { icon: <StaticImage src="../../images/renvelope-icn.svg" layout="constrained" />, desc: `<p>Questions about these requirements can be sent to <a href="mailto:applications@dfwlabrescue.org">applications@dfwlabrescue.org</a>.</p>` },
    ]
  

    return <section className="adoption-sec4">
        <div id="rfh" className="content">
            <h3>Requirements of a Forever Home</h3>
            <div className="items">
                {data && data.map((item, key) => {
                    return <div key={key} className="item">
                        <div className="icon">{item.icon}</div>
                        <div className="desc" dangerouslySetInnerHTML={{ __html: item.desc }} />
                    </div>
                })}
            </div>
        </div>
    </section>
}

const Sections = {
    Section1,
    Section2,
    Section3,
    Section4
}

export default Sections